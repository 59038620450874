import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import replaceFootnotes from "../logic/replaceFootnotes";

const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ previous, next }) =>
    next && !previous ? "flex-end" : "space-between"};
  list-style: none;
  padding: 0;
`;

const PageThumbs = ({ pageContext: { next, previous } }) => {
  return previous || next ? (
    <ListWrapper previous={previous} next={next}>
      {previous && (
        <li key="prev">
          <Link to={previous.fields.slug} rel="prev">
            ← {replaceFootnotes(previous.title)}
          </Link>
        </li>
      )}
      {next && (
        <li key="next" previous={previous} next={next}>
          <Link to={next.fields.slug} rel="next">
            {replaceFootnotes(next.title)} →
          </Link>
        </li>
      )}
    </ListWrapper>
  ) : null;
};

export default PageThumbs;
