import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
  margin-right: -70px;
`;

const Stars = ({ id, onClick, stars, width = 100 }) => {
  const gradientId = Math.random();

  return (
    <StyledSvg
      height={(width * 20) / 100}
      id={id}
      onClick={onClick}
      viewBox="0 0 100 20"
      width={100}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="0%"
          id={gradientId}
          gradientUnits="userSpaceOnUse"
          suppressHydrationWarning={true}
        >
          <stop stopColor="#ffcc33" offset={`${(stars / 5.0) * 100}%`} />
          <stop stopColor="#aaa" offset="0%" />
        </linearGradient>
      </defs>
      <g
        fill={`url(#${gradientId})`}
        stroke="#B89301"
        suppressHydrationWarning={true}
      >
        <path d="M4.53 18.523l1.2-6.167-4.416-4.343 6.066-.73 2.646-5.668 2.548 5.716 6.052.84-4.492 4.262 1.095 6.188-5.324-3.083zM24.503 0" />
      </g>
    </StyledSvg>
  );
};

export { Stars };
