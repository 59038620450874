import { graphql } from "gatsby";
import { Grid } from "grommet";
import Image from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import CenteredText from "../components/centered-text";
import { genericScrollHandler } from "../hooks/useScrollHandler";
import Layout from "../components/layout";
import PageThumbs from "../components/page-thumbs";
import { Rating } from "../components/rating";
import RecipeMetadata from "../components/recipe-metadata";
import SEO from "../components/seo";
import { Stars } from "../components/stars";

const RecipeWrapper = styled.div`
  > ul {
    list-style-type: ${({ listType }) => (listType ? listType : "none")};
  }
`;

const Text = styled.div`
  display: ${({ display }) => (display ? display : "block")};
  margin-top: 10px;

  @media (max-width: ${Constants.mobileWidth}) {
    > small {
      text-align: center;
    }
  }
`;

const SmallText = styled.small`
  @media (max-width: ${Constants.mobileWidth}) {
    display: block;
  }

  &::after {
    content: "${({ showContent }) => (showContent && showContent ? "" : ".")}";
    @media (max-width: ${Constants.mobileWidth}) {
      content: "";
    }
  }
`;

const RecipeTitle = styled.h1`
  font-size: 35px;
  @media (max-width: ${Constants.mobileWidth}) {
    font-size: 20px;
  }
`;

const RecipeSectionTitle = styled.h2`
  font-size: 16px;
`;

const StyledRecipeImage = styled(Image)`
  margin: 10px 0 10px 0;
`;

const RatingLink = styled.a`
  box-shadow: none;
`;

const LazyImage = ({ image }) => (
  <Image
    fluid={[
      image.childImageSharpMobile.fluid,
      {
        ...image.childImageSharpDesktop.fluid,
        media: `min-width: 900`
      }
    ]}
    loading="auto"
    key={image.childImageSharpMobile.src}
    itemProp="image"
  />
);

const createImageGrid = images => {
  if (!images) return;

  return images.length === 1 ? (
    <LazyImage
      image={images[0]}
      key={images[0].childImageSharpMobile.fluid.src}
    />
  ) : (
    <Grid
      justifyContent="start"
      gap={"small"}
      columns={[`1fr`, `1fr`]}
      margin="1rem 0 0 0"
    >
      {images.map(image => (
        <LazyImage image={image} key={image.childImageSharpMobile.fluid.src} />
      ))}
    </Grid>
  );
};

const getTwoImages = images => {
  const returnArray = [];
  if (!images) return returnArray;
  for (let index = 0; index <= 1; index++) {
    if (images[index]) {
      returnArray.push(images.shift());
    } else if (images[0]) {
      returnArray.push(images.pop());
    }
  }
  return returnArray;
};

const calculateRecipeTime = time => {
  return time > 60 ? `${(time / 60).toPrecision(2)} hours` : `${time} minutes`;
};

const getStarRatings = numberOfRatings => {
  const totalStars = numberOfRatings < 1 ? 1 : numberOfRatings;
  const starList = [];
  for (let index = 0; index < totalStars; index++) {
    starList.push(<Stars stars={5} key={index} />);
  }
  return (
    <RatingLink href="#ratings" onClick={genericScrollHandler}>
      {starList.map(star => star)}
    </RatingLink>
  );
};

const Recipe = ({
  data: { avatar, backdrop, logo, recipe, site },
  location,
  pageContext
}) => {
  const photos = recipe.data.Photos && [
    ...recipe.data.Photos.localFiles.map(x => x)
  ];
  const firstImage = photos.length > 0 && photos.shift();
  const firstImageAlt = firstImage && `${recipe.data.Name.trim()} cover photo`;
  const secondImages = getTwoImages(photos);
  const thirdImages = getTwoImages(photos);

  return (
    <Layout avatar={avatar} backdrop={backdrop} location={location} site={site}>
      <SEO
        avatar={avatar}
        logo={logo}
        description={recipe.data.Subheadline}
        postData={{
          keywords: recipe.data.Keywords.split(",") || [
            "vegan recipe",
            "vegetarian recipe"
          ],
          author: recipe.data.Author,
          image: firstImage && firstImage.childImageSharpMobile.fluid.src,
          imageAlt: firstImageAlt
        }}
        site={site}
        title={`${recipe.data.Name} recipe | ${site.siteMetadata.title}`}
      />
      <RecipeWrapper
        id="fullRecipeWrapper"
        itemType="https://schema.org/Recipe"
        itemScope={true}
      >
        <RecipeMetadata recipe={recipe} />
        <CenteredText>
          <RecipeTitle itemProp="headline name">
            {recipe.data.Name.toUpperCase()}
          </RecipeTitle>
        </CenteredText>
        {firstImage && (
          <StyledRecipeImage
            itemProp="image"
            fluid={[
              firstImage.childImageSharpMobile.fluid,
              {
                ...firstImage.childImageSharpDesktop.fluid,
                media: `min-width: 600`
              }
            ]}
            loading="eager"
            title={firstImageAlt}
            alt={firstImageAlt}
          />
        )}
        <Text display="inline">
          <SmallText>Published: {recipe.data.PublishDate}</SmallText>{" "}
        </Text>{" "}
        <Text display="inline">
          <SmallText>
            Prep time: {calculateRecipeTime(recipe.data.PrepTime)}
          </SmallText>
        </Text>{" "}
        <Text display="inline">
          <SmallText>
            Baking time: {calculateRecipeTime(recipe.data.CookTime)}
          </SmallText>
        </Text>{" "}
        <Text display="inline">
          <SmallText>Serves: {recipe.data.Yield}</SmallText>
        </Text>{" "}
        <Text>
          <SmallText showContent={true}>
            Rating: {getStarRatings(recipe.data.Rating)}
          </SmallText>
        </Text>{" "}
        <Text display="inline">
          <SmallText showContent={true}>
            # of Ratings: {recipe.data.NumberOfRatings}
          </SmallText>
        </Text>
        <Text
          dangerouslySetInnerHTML={{
            __html: recipe.data.Intro.childMarkdownRemark.html
          }}
          itemProp="description"
        />
        {secondImages.length > 0 && createImageGrid(secondImages)}
        <RecipeSectionTitle>Ingredients</RecipeSectionTitle>
        <RecipeWrapper
          dangerouslySetInnerHTML={{
            __html: recipe.data.Ingredients.childMarkdownRemark.html
          }}
          itemProp="recipeIngredient"
        />
        {thirdImages.length > 0 && createImageGrid(thirdImages)}
        <RecipeSectionTitle>Steps</RecipeSectionTitle>
        <RecipeWrapper
          dangerouslySetInnerHTML={{
            __html: recipe.data.Notes.childMarkdownRemark.html
          }}
          itemProp="recipeInstructions"
          listType="decimal"
        />
        {photos.length > 0 && createImageGrid(photos)}
        <Rating recipeId={recipe.recordId} />
      </RecipeWrapper>
      <PageThumbs pageContext={pageContext} />
    </Layout>
  );
};

export default Recipe;

export const pageQuery = graphql`
  query RecipeBySlug($slug: String!) {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    recipe: airtable(fields: { slug: { eq: $slug } }) {
      ...RecipeWithPhotos
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;
