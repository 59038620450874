import React, { useState } from "react";

import Emoji from "./emoji";
import { fetchHelper } from "../utils/fetchHelper";
import { Stars } from "./stars";

const onClickHandler = async (event, recipeId, setSubmissionText) => {
  event.preventDefault();
  setSubmissionText("Submitting ...");

  const response = await fetchHelper({
    url: "/.netlify/functions/rating-handler",
    body: {
      rating: event.currentTarget.id,
      recipeId
    }
  });

  if (!response.success) {
    setSubmissionText(
      `There was a problem submitting your recipe rating. Try refreshing the page? If there's still an issue, please <a href="/chase-us/#contact-us">Contact Us</a>.`
    );
  } else {
    setSubmissionText("Thanks for submitting your rating!");
  }
};

const allStars = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

const ShowStars = ({ recipeId, setSubmissionText, submissionText }) => (
  <>
    {!submissionText &&
      allStars.map(star => (
        <Stars
          stars={1}
          id={star.id}
          key={star.id}
          onClick={e => onClickHandler(e, recipeId, setSubmissionText)}
        />
      ))}
    <div dangerouslySetInnerHTML={{ __html: submissionText }} />
  </>
);

export const Rating = ({ recipeId }) => {
  const [submissionText, setSubmissionText] = useState(null);

  return (
    <div id="#ratings">
      <h2>Rate our recipe!</h2>
      <p>
        Thanks for reading our recipe and we really hope you enjoyed it! We're
        always happy to get feedback on the quality our recipes provided - if
        you liked it, make sure you click on the fifth{" "}
        <Emoji label="star" symbol="⭐" /> to give this recipe a top-notch
        rating.
      </p>
      <ShowStars
        recipeId={recipeId}
        setSubmissionText={setSubmissionText}
        submissionText={submissionText}
      />
    </div>
  );
};
